import React from 'react'

const Resume = () => {
  return (
    <>
    <div className="resumePaddingContainer">
    <div className="resumeContainer">
      <a
        target="_blank" className="resumeButton"
        href="https://drive.google.com/open?id=1EMzQuYQ-8-7uDcC3M1qrXBe3QzXIMdpO"
      >
        Resume
      </a>
    </div>
    </div>
    </>
  )
}

export default Resume
